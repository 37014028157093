import {createWebHistory, createRouter} from "vue-router";
import Dashboard from '../pages/Dashboard';
import Login from '../pages/Login';
import Register from '../pages/Register';
import Confirm from '../pages/Confirm';

import ClientsCreate from '../pages/clients/Create';
import ClientsShow from '../pages/clients/Show';
import ClientsEdit from '../pages/clients/Edit';
import ClientsIndex from '../pages/clients/Index';

import ClientEventsShow from '../pages/client_events/Show';
import ClientEventsEdit from '../pages/client_events/Edit';
import ClientEventsIndex from '../pages/client_events/Index';

import ContractsCreate from '../pages/contracts/Create';
import ContractsShow from '../pages/contracts/Show';
import ContractsEdit from '../pages/contracts/Edit';
import ContractsIndex from '../pages/contracts/Index';

import DirectDebitMandatesCreate from '../pages/direct_debit_mandates/Create';
import DirectDebitMandatesEdit from '../pages/direct_debit_mandates/Edit';
import DirectDebitMandatesIndex from '../pages/direct_debit_mandates/Index';

import ContactsShow from '../pages/contacts/Show';
import ContactsEdit from '../pages/contacts/Edit';
import ContactsIndex from '../pages/contacts/Index';

import ProductsIndex from '../pages/products/Index';
import ProductsShow from '../pages/products/Show';
import ProductsEdit from '../pages/products/Edit';
import ProductsCreate from '../pages/products/Create';

import ProductBillingPeriodsIndex from '../pages/product_billing_periods/Index';
import ProductBillingPeriodsEdit from '../pages/product_billing_periods/Edit';

import OrganizationCreate from '../pages/organizations/Create';
import Organizations from '../pages/organizations/Index';
import OrganizationShow from '../pages/organizations/Show';
import UsersIndex from '../pages/users/Index';
import UsersEdit from '../pages/users/Edit';
import Invoices from '../pages/invoices/Index';

import InvoicesCreate from '../pages/invoices/Create';
import InvoicesEdit from '../pages/invoices/Edit';
import Dunning from '../pages/dunning/Index';
import DunningEdit from '../pages/dunning/Edit';
import Accounts from '../pages/accounts/Index';
import AccountsShow from '../pages/accounts/Show';
import AccountsTags from '../pages/accounts/Tags';
import AccountsImportTransactions from '../pages/accounts/ImportTransactions.vue';
import AccountsCreate from '../pages/accounts/Create';
import AccountsAnalytics from '../pages/accounts/Analytics';
import AccountsCallback from '../pages/accounts/Callback';

import ProductTypes from '../pages/product_types/Index';

import ReceiptsIndex from '../pages/receipts/Index';
import ReceiptsCreate from '../pages/receipts/Create.vue';

import i18n from '../i18n'
import {AUTH_TOKEN_NAME, LAST_FULL_PATH, ROUTES} from "./constants";
import {useClientStore} from "@/stores/client";

const PUBLIC_ROUTES = [
    ROUTES.LOGIN,
    ROUTES.REGISTER,
    ROUTES.CONFIRM,
]

export const routes = [
    {
        path: '/',
        redirect: '/de/app/dashboard' // FIXME: assume de as locale?
    },
    {
        path: '/:locale/app',
        beforeEnter: (to, from, next) => {
            const locale = to.params.locale;
            const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',');
            if (!supported_locales.includes(locale)) return next('en');
            if (!localStorage[AUTH_TOKEN_NAME] && !PUBLIC_ROUTES.includes(to.name)) {
                sessionStorage.setItem(LAST_FULL_PATH, to.fullPath)
                return next({name: ROUTES.LOGIN, params: {locale}})
            }
            if (localStorage[AUTH_TOKEN_NAME] && to.name === ROUTES.LOGIN) {
                return next({name: ROUTES.DASHBOARD, params: {locale}})
            }
            if (i18n.global.locale !== locale) {
                i18n.global.locale = locale
            }
            return next()
        },
        component: {
            template: "<router-view></router-view>"
        },
        children: [
            {
                name: ROUTES.CONFIRM,
                path: 'confirm/:id',
                component: Confirm
            },
            {
                name: ROUTES.LOGIN,
                path: 'login',
                component: Login
            },
            {
                name: ROUTES.REGISTER,
                path: 'register',
                component: Register
            },
            {
                name: ROUTES.DASHBOARD,
                path: 'dashboard',
                component: Dashboard
            },
            {
                path: 'contracts',
                component: {
                    template: "<router-view></router-view>"
                },
                children: [
                    {
                        path: '',
                        component: ContractsIndex,
                        name: ROUTES.CONTRACTS.INDEX,
                    },
                    {
                        path: 'create',
                        component: ContractsCreate,
                        name: ROUTES.CONTRACTS.CREATE,
                    },
                    {
                        path: ':id',
                        component: ContractsShow,
                        name: ROUTES.CONTRACTS.SHOW,
                        meta: { resetClient: true },
                    },
                    {
                        path: 'edit/:id',
                        component: ContractsEdit,
                        name: ROUTES.CONTRACTS.EDIT,
                        meta: { resetClient: true },
                    },
                ]
            },
            {
                path: 'contacts',
                component: {
                    template: "<router-view></router-view>"
                },
                children: [
                    {
                        path: '',
                        component: ContactsIndex,
                        name: ROUTES.CONTACTS.INDEX,
                    },
                    {
                        path: ':id',
                        component: ContactsShow,
                        name: ROUTES.CONTACTS.SHOW,
                    },
                    {
                        path: 'edit/:id',
                        component: ContactsEdit,
                        name: ROUTES.CONTACTS.EDIT,
                    },
                ]
            },
            {
                path: 'client_events',
                component: {
                    template: "<router-view></router-view>"
                },
                children: [
                    {
                        path: '',
                        component: ClientEventsIndex,
                        name: ROUTES.CLIENT_EVENTS.INDEX,
                    },
                    // {
                    //     path: 'create',
                    //     component: ClientEventsCreate,
                    //     name: 'client_events.create',
                    // },
                    {
                        path: ':id',
                        component: ClientEventsShow,
                        name: ROUTES.CLIENT_EVENTS.SHOW,
                        meta: { resetClient: true }
                    },
                    {
                        path: 'edit/:id',
                        component: ClientEventsEdit,
                        name: ROUTES.CLIENT_EVENTS.EDIT,
                        meta: { resetClient: true }
                    },
                ]
            },
            {
                path: 'clients',
                component: {
                    template: "<router-view></router-view>"
                },
                children: [
                    {
                        path: '',
                        component: ClientsIndex,
                        name: ROUTES.CLIENTS.INDEX,
                    },
                    {
                        path: 'create',
                        component: ClientsCreate,
                        name: ROUTES.CLIENTS.CREATE,
                    },
                    {
                        path: ':id',
                        component: ClientsShow,
                        name: ROUTES.CLIENTS.SHOW,
                        meta: { resetClient: true },
                    },
                    {
                        path: 'edit/:id',
                        component: ClientsEdit,
                        name: ROUTES.CLIENTS.EDIT,
                        meta: { resetClient: true },
                    },
                ]
            },
            {
                path: 'direct_debit_mandates',
                component: {
                    template: "<router-view></router-view>"
                },
                children: [
                    {
                        path: '',
                        component: DirectDebitMandatesIndex,
                        name: ROUTES.DIRECT_DEBIT_MANDATES.INDEX,
                    },
                    {
                        path: 'create',
                        component: DirectDebitMandatesCreate,
                        name: ROUTES.DIRECT_DEBIT_MANDATES.CREATE,
                    },
                    {
                        path: 'edit/:id',
                        component: DirectDebitMandatesEdit,
                        name: ROUTES.DIRECT_DEBIT_MANDATES.EDIT,
                    },
                ]
            },
            {
                path: 'accounts',
                component: {
                    template: "<router-view></router-view>"
                },
                children: [
                    {
                        path: '',
                        component: Accounts,
                        name: ROUTES.ACCOUNTS.INDEX,
                        meta: { resetClient: true }
                    },
                    {
                        path: 'create',
                        component: AccountsCreate,
                        name: ROUTES.ACCOUNTS.CREATE,
                        meta: { resetClient: true }
                    },
                    {
                        path: 'tags',
                        component: AccountsTags,
                        name: ROUTES.ACCOUNTS.TAGS,
                        meta: { resetClient: true }
                    },
                    {
                        path: ':id/import',
                        component: AccountsImportTransactions,
                        name: ROUTES.ACCOUNTS.IMPORT_TRANSACTIONS,
                        meta: { resetClient: true }
                    },
                    {
                        path: ':id/analytics',
                        component: AccountsAnalytics,
                        name: ROUTES.ACCOUNTS.ANALYTICS,
                        meta: { resetClient: true }
                    },
                    {
                        path: ':id',
                        component: AccountsShow,
                        name: ROUTES.ACCOUNTS.SHOW,
                        meta: { resetClient: true }
                    },
                    {
                        path: 'auth_callback',
                        component: AccountsCallback,
                        name: ROUTES.ACCOUNTS.CALLBACK,
                    },
                ]
            },
            {
                path: 'organizations',
                component: {
                    template: "<router-view></router-view>"
                },
                children: [
                    {
                        path: '',
                        component: Organizations,
                        name: ROUTES.ORGANIZATIONS.INDEX,
                    },
                    {
                        path: 'create',
                        component: OrganizationCreate,
                        name: ROUTES.ORGANIZATIONS.CREATE,
                    },
                    {
                        path: ':id',
                        component: OrganizationShow,
                        name: ROUTES.ORGANIZATIONS.SHOW,
                    },
                ]
            },
            {
                path: 'product_types',
                component: {
                    template: "<router-view></router-view>"
                },
                children: [
                    {
                        path: '',
                        component: ProductTypes,
                        name: ROUTES.PRODUCT_TYPES.INDEX,
                    },
                ]
            },
            {
                path: 'users',
                component: {
                    template: "<router-view></router-view>"
                },
                children: [
                    {
                        path: '',
                        component: UsersIndex,
                        name: ROUTES.USERS.INDEX,
                    },
                    {
                        path: 'edit/:id',
                        component: UsersEdit,
                        name: ROUTES.USERS.EDIT,
                    },
                ]
            },
            {
                path: 'invoices',
                component: {
                    template: "<router-view></router-view>"
                },
                children: [
                    {
                        path: '',
                        component: Invoices,
                        name: ROUTES.INVOICES.INDEX,
                    },
                    {
                        path: 'create',
                        component: InvoicesCreate,
                        name: ROUTES.INVOICES.CREATE,
                    },
                    {
                        path: 'edit/:id',
                        component: InvoicesEdit,
                        name: ROUTES.INVOICES.EDIT,
                        meta: { resetClient: true },
                    },
                ]
            },
            {
                path: 'products',
                component: {
                    template: "<router-view></router-view>"
                },
                children: [
                    {
                        path: '',
                        component: ProductsIndex,
                        name: ROUTES.PRODUCTS.INDEX,
                    },
                    {
                        path: ':product_id',
                        component: ProductsShow,
                        name: ROUTES.PRODUCTS.SHOW,
                        meta: { resetClient: true },
                    },
                    {
                        path: 'edit/:id',
                        component: ProductsEdit,
                        name: ROUTES.PRODUCTS.EDIT,
                        meta: { resetClient: true },
                    },
                    {
                        path: 'create',
                        component: ProductsCreate,
                        name: ROUTES.PRODUCTS.CREATE,
                    },
                ]
            },
            {
                path: 'product_billing_periods',
                component: {
                    template: "<router-view></router-view>"
                },
                children: [
                    {
                        path: '',
                        component: ProductBillingPeriodsIndex,
                        name: ROUTES.PRODUCT_BILLING_PERIODS.INDEX,
                    },
                    {
                        path: 'edit/:id',
                        component: ProductBillingPeriodsEdit,
                        name: ROUTES.PRODUCT_BILLING_PERIODS.EDIT,
                    },
                ]
            },
            {
                path: 'dunning',
                component: {
                    template: "<router-view></router-view>"
                },
                children: [
                    {
                        path: '',
                        component: Dunning,
                        name: ROUTES.DUNNING.INDEX,
                    },
                    {
                        path: 'edit/:invoice_id?',
                        component: DunningEdit,
                        name: ROUTES.DUNNING.EDIT,
                        meta: { resetClient: true },
                    },
                ]
            },
            {
                path: 'receipts',
                component: {
                    template: "<router-view></router-view>"
                },
                children: [
                    {
                        path: '',
                        component: ReceiptsIndex,
                        name: ROUTES.RECEIPTS.INDEX,
                    },
                    {
                        path: 'create',
                        component: ReceiptsCreate,
                        name: ROUTES.RECEIPTS.CREATE,
                    },
                ]
            },
        ]
    },


];

const router = createRouter({
    history: createWebHistory(),
    routes
});

//change route navigation guard to "beforeEach" since afterEach() is getting called multiple times (reason unknown)
router.beforeEach((to, from) => {
    if(from.meta.resetClient && !to.meta.resetClient) {
        useClientStore().client = {};
    }
})
// router.afterEach((to) => {
    // if(to.name !== ROUTES.LOGIN) {
    //     sessionStorage.setItem(LAST_FULL_PATH, to.fullPath)
    // }
// });
export default router;
