import {createApp} from 'vue/dist/vue.esm-bundler';
import {pinia} from './stores'
import App from './App.vue'
import i18n from './i18n'
import router from './scripts/routes'
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import DialogService from 'primevue/dialogservice';
import Column from 'primevue/column';
import Row from 'primevue/row';
import ColumnGroup from 'primevue/columngroup';
import 'bootstrap'
import './scss/app/index.scss'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import {useSetupStore} from "./stores/setup";
import {useClientStore} from "@/stores/client";
import dayjs from "dayjs";
import ActionCableVue from "actioncable-vue";
import {useGlobalStore} from "@/stores/global";
import {WS_API_URL} from "@/scripts/constants";
import Autolinker from 'autolinker';
const app = createApp(App)

app.use(ActionCableVue, {
    debug: true,
    debugLevel: "error",
    // connectionUrl: "ws://localhost:3016/api/v1/cable",
    connectionUrl: WS_API_URL,
    connectImmediately: true,
})
app.use(router)
app.use(pinia)
app.use(i18n)
app.use(PrimeVue);
app.use(DialogService);
app.component('DataTable', DataTable);
app.component('TableColumn', Column);
app.component('TableRow', Row);
app.component('ColumnGroup', ColumnGroup);
app.component('DatePicker', Datepicker);

app.directive('focus', {
    mounted(el) { // When the bound element is inserted into the DOM...
        setTimeout(() => {
            el.focus() // Focus the element
        }, 100)
    }
})

const setupStore = useSetupStore()
const clientStore = useClientStore()
const globalStore = useGlobalStore()
app.mixin({
    methods: {
        salutation(pronouns) {
            return this.$t(`contacts.salutations.${pronouns}`)
        },
        autolink(text, options) {
            return Autolinker.link(text, options)
        },
        // null => reset
        // {} => ignore
        // { name: ..., id: ... } => use
        resetClient() {
            clientStore.client = {}
        },
        setCurrentClient(client) {
            if (typeof client === 'object' && Object.keys(client).length) {
                clientStore.client = client
            }
        },
        setCurrentClientById(clientId) {
            clientStore.get(clientId).then(({data}) => {
                this.setCurrentClient(data.client)
            })
        },
        setTitle(title = 'missing title') {
            globalStore.setTitle(title)
        },
        formatCurrency(amount, options = {}) {
            if (amount == null || typeof (amount) == "undefined") {
                return
            }
            //TODO receive locale/currency from user
            const locale = 'de-DE'
            const defaultOptions = {style: 'currency', currency: 'EUR'}
            options = Object.assign(defaultOptions, options)
            return new Intl.NumberFormat(locale, options).format(amount)
        },
        formatPercentage(value) {
            if (!value) {
                return
            }
            //TODO receive locale/currency from user
            const locale = 'de-DE'
            return Number(value/100).toLocaleString(locale,{style: 'percent', minimumFractionDigits:0});
        },
        formatDate(dateTime, format) {
            try {
                if (!dateTime) {
                    // return 'invalid date/time'
                    return
                }
                const value = this.$d(dateTime, format)
                if (value === '00:00') {
                    return
                }
                return value
            } catch (e) {
                console.error(e)
            }
            return `failed: ${dateTime}`
        },
        dateStillToCome(dateString) {
            return dayjs(dateString).isAfter(dayjs(), 'day');
        },
        dateAlreadyPassed(dateString) {
            return dayjs(dateString).isBefore(dayjs(), 'day');
        },
        dateColor(dateString) {
            if (!dateString) {
                return '--bs-secondary'
            } else if (this.dateAlreadyPassed(dateString)) {
                return '--bs-danger'
            } else if (this.dateStillToCome(dateString)) {
                return '--bs-success'
            } else {
                return '--bs-warning'
            }
        }
    },
    data() {
        return {
            // classAdminButton: 'btn-info text-white',
            classAdminIcon: 'fa-screwdriver-wrench',
        }
    },
    computed: {
        axiosIsLoading() {
            return setupStore.axiosLoading
        },
        clientIsSet() {
            return !clientStore.client.isE
        }
    }
})
app.mount('#app')
