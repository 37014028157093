<template>
  <ul class="timeline mt-4 col-12">
    <li v-bind:key="key" v-for="(item, key) in items">
      <div class="timeline-time">
        <div class="mb-3">
          <template v-if="allowChildren && item.children.length">
            <small>{{ formatDate(item.created_at, 'short2') }} - </small>
            <span class="date">{{ formatDate(item.children[item.children.length - 1].created_at, 'short2') }}</span>
          </template>
          <template v-else>
            <span class="date">{{ formatDate(item.created_at, 'short2') }}</span>
          </template>
        </div>

        <div class="d-flex flex-column align-items-end">
          <template v-if="item.resubmission_date">
            <ClientEventTimelineItemResubmissionPreview :item="item" class="justify-content-end" />
          </template>
          <template v-else>
            <ClientEventTimelineItemResubmissionDialog :item="item"/>
          </template>
        </div>
      </div>

      <div class="timeline-icon ps-3">
        <i class="fa-xl" :class="setupStore.getClientEventTypeById(item.client_event_type_id)?.icon"
           :style="`color: ${setupStore.getClientEventTypeById(item.client_event_type_id)?.color}`"
        ></i>
      </div>

      <div class="timeline-body pt-0 prepare-delete-hover"
           :class="{ 'me-0': !allowChildren, 'delete-hover': hoverDeleteById[item.id] }">
        <ClientEventTimelineItem :item="item" :showClient="showClient" :is-headline-item="true"
                                 @hoverDelete="setHoverDelete"/>
        <template v-if="item.children.length">
          <ul class="list-group">
            <template v-for="child in item.children" :key="child.id">
              <li class="list-group-item timeline-item border-0 p-0 mb-3">
                <ClientEventTimelineItem :item="child" :parentItem="item" />
              </li>
            </template>
          </ul>
        </template>

        <template v-if="!item.parent_id"> <!-- already child -> prevent grandchilds -->
          <!--          <template v-if="item.resubmission_date">{{ item.resubmission_date }} {{ item.assigned_user_id }}</template>-->


          <ClientEventAppendButton :parentId="item.id" :item-key="key" :parent-event="item"/>
          <ClientEventTimelineItemResubmissionPreview :item="item" :force-wrap-after-date="false" class="ms-5" :show-label="true">
            <template v-if="this.productBillingPeriodStore.client_event_needs_renewal[item.id]">
              <div class="alert alert-warning mb-0 ms-2 py-1 ">{{ $t('product_billing_periods.still_need_renewal') }}</div>
            </template>
          </ClientEventTimelineItemResubmissionPreview>
        </template>
      </div>
    </li>
  </ul>
</template>

<script>
import {useAllUsersStore} from "@/stores/all_users";
import {useProductBillingPeriodStore} from "@/stores/product_billing_period";
import {useSetupStore} from "@/stores/setup";
import ClientEventAppendButton from "@/components/ClientEventAppendButton.vue";
import ClientEventTimelineItem from "@/components/ClientEventTimelineItem.vue";
import ClientEventTimelineItemResubmissionDialog from "@/components/ClientEventTimelineItemResubmissionDialog.vue";
import ClientEventTimelineItemResubmissionPreview from "@/components/ClientEventTimelineItemResubmissionPreview.vue";


export default {
  //from https://www.bootdey.com/snippets/view/bs4-timeline
  name: 'ComponentClientEventTimeline',
  components: {
    ClientEventAppendButton,
    ClientEventTimelineItem,
    ClientEventTimelineItemResubmissionDialog,
    ClientEventTimelineItemResubmissionPreview,
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
    showClient: {
      type: Boolean,
      default: false,
    },
    allowComment: {
      type: Boolean,
      default: true,
    },
    allowChildren: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      productBillingPeriodStore: useProductBillingPeriodStore(),
      setupStore: useSetupStore(),
      useAllUsersStore: useAllUsersStore(),
    }
  },
  data() {
    return {
      hoverDeleteById: {},
    }
  },
  methods: {
    setHoverDelete(value, id) {
      this.hoverDeleteById[id] = value
    }
  },
  computed: {},
  created() {
    this.setupStore.getClientEventTypes()
  }
}
</script>
<style lang="scss">
.timeline {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 5px;
    bottom: 5px;
    width: 5px;
    background: #2d353c;
    left: 9%;
    margin-left: -2.5px;
  }

  > li {
    position: relative;
    min-height: 50px;
    padding: 20px 0;
  }

  .timeline-time {
    position: absolute;
    left: 0;
    width: 8%;
    top: 30px;
    text-align: right;

    .date {
      display: block;
      font-weight: 600;
      line-height: 16px;
      font-size: 1.0em;
    }

    .time {
      display: block;
      font-weight: 600;
      line-height: 24px;
      font-size: 0.8em;
      color: #242a30;
    }
  }

  .timeline-icon {
    left: 5.5%;
    position: absolute;
    width: 10%;
    text-align: center;
    top: 40px;

    i {
      &.fixme {
        &::after {
          content: "icon missing!";
          font-size: 10px
        }

        font-size: 25px;
      }
    }
  }

  .timeline-body {
    margin-left: 15%;
    margin-right: 5%;
    background: #fff;
    position: relative;
    //border-radius: 6px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      border: 10px solid transparent;
      border-right-color: #fff;
      left: -20px;
      top: 20px;
    }

    > div + div {
      margin-top: 15px;

      &:last-child {
        margin-bottom: -20px;
        padding-bottom: 20px;
        //border-radius: 0 0 6px 6px;
      }
    }

    .timeline-item {
      //background: #ececec;
      border-radius: 0;
      margin-bottom: 1rem;

      .timeline-header {
        border-bottom: 1px solid black;
      }
    }
  }

  .prepare-delete-hover {
    /* setup transparent border which gets colored by .delete-hover */
    border: 1px solid transparent;
  }

  .delete-hover {
    border: 1px dashed red;
  }

  img {
    max-width: 100%;
    display: block;
  }
}

.timeline-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #e2e7eb;
  line-height: 30px;

  .userimage {
    float: left;
    width: 34px;
    height: 34px;
    border-radius: 40px;
    overflow: hidden;
    margin: -2px 10px -2px 0;
  }

  .username {
    font-size: 16px;
    font-weight: 600;
    color: #2d353c;

    a {
      color: #2d353c;
    }
  }
}

.timeline-content {
  letter-spacing: .25px;
  line-height: 18px;
  //font-size: 13px;

  &:after {
    content: '';
    display: table;
    clear: both;
  }

  &:before {
    content: '';
    display: table;
    clear: both;
  }
}

.timeline-title {
  margin-top: 0;
}

.timeline-footer {
  background: #fff;
  border-top: 1px solid #e2e7ec;
  padding-top: 15px;

  a {
    &:not(.btn) {
      color: #575d63;

      &:focus {
        color: #2d353c;
      }

      &:hover {
        color: #2d353c;
      }
    }
  }
}

.timeline-comment-box {
  background: #f2f3f4;
  padding: 20px 25px;

  .user {
    float: left;
    width: 34px;
    height: 34px;
    overflow: hidden;
    border-radius: 30px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .user + .input {
    margin-left: 44px;
  }
}

.lead {
  margin-bottom: 20px;
  font-size: 21px;
  font-weight: 300;
  line-height: 1.4;
}

.text-danger {
  color: #ff5b57 !important;
}

.text-red {
  color: #ff5b57 !important;
}

</style>