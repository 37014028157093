import {createNanoEvents} from 'nanoevents'
import router from "@/scripts/routes";

Object.defineProperty(String.prototype, 'capitalize', {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false
});
// "isEmpty" already defined by echarts
Object.defineProperty(Object.prototype, 'isE', {
    get() {
        return Object.getPrototypeOf(this) === Object.prototype &&
            Object.getOwnPropertyNames(this).length === 0 &&
            Object.getOwnPropertySymbols(this).length === 0
    }
});
Object.defineProperty(Object.prototype, 'isPresent', {
    get() {
        return !this.isE
    }
});
String.prototype.truncate = String.prototype.truncate ||
    function (n, useWordBoundary) {
        if (this.length <= n) {
            return this;
        }
        const subString = this.slice(0, n - 1); // the original check
        return (useWordBoundary
            ? subString.slice(0, subString.lastIndexOf(" "))
            : subString) + "&hellip;";
    };
const Helpers = {
    // eslint-disable-next-line
    emitter: createNanoEvents(),
    textToBlob:(data) => {
        // const blob = new Blob(["Hello, world!"], {type: "text/plain;charset=utf-8"});
        return new Blob([data.data], {type: data.type});
    },
    base64ToBlob: (data) => {
        const byteCharacters = atob(data.blob);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], {type: data.type || "application/pdf"});
    },
    base64ToFile(data, filename,) {
        const blob = Helpers.base64ToBlob(data)
        return new File([blob], filename, {type: data.type || "application/pdf"})
    },
    formatDateTech(date) {
        // daysjs
        if (date.format) {
            return date.format('YYYY-MM-DD');
        }

        // js date
        let month = '' + (date.getMonth() + 1),
            day = '' + date.getDate(),
            year = date.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    },

    addQueryToParams(query, params) {
        for (const key in query) {
            if (Object.prototype.hasOwnProperty.call(query, key)) {
                params[key] = query[key]
            }
        }
        return params
    },
    async setUrlQuery(query) {
        //important: copy object to force url change
        let currentQueries = Object.assign({}, router.currentRoute.value.query);
        await router.push({query: {...currentQueries, ...query}});
    },
    async removeQueryFromUrl(key) {
        //important: copy object to force url change
        let queries = Object.assign({}, router.currentRoute.value.query);
        // console.log(`remove: ${key}`, queries)
        delete queries[key]
        // console.log(`remove after: ${key}`, queries)
        await router.push({query: queries});
    },
}

export {Helpers};